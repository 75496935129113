<template>
  <div class="content">
    <div v-if="activity">
      
      <ui-tabs v-model="active_tab">
        <ui-tab
          v-for="(tab, index) in tabs"
          :key="index"
          type="iconOnly"
          :icon="tab.icon"
        >
        </ui-tab>
      </ui-tabs>
      <ui-panels v-model="active_tab" class="activity-content">

        <!-- Main Pannel -->
        <ui-panel>
          <div id="add_entries">
            <ui-textfield class="input_item number_input" type="number" input-type="number" outlined placeholder="Number of #" v-model="add_count" />
            <ui-select class="input_item day_select" v-model="day_select" outlined :options="day_options">
                Date
            </ui-select>
            <ui-button secondary raised class="input_item" @click="add_entry">Add</ui-button>
            <ui-spinner v-if="submitting" active size="small"></ui-spinner>
          </div>

          <week-vue v-if="activity.entries" :id="$route.params.id" :past_week="past_week"></week-vue>
        </ui-panel>

        <!-- Edit Entries -->
        <ui-panel>
          <EntriesEdit></EntriesEdit>
        </ui-panel>
        <ui-panel>
          <h3>If the user is signed up add them to the activity</h3>
          <ui-textfield input-type="text" outlined placeholder="email" v-model="invite_email">Email</ui-textfield>
          <p v-if="invite_error_text">{{invite_error_text}}</p>
          <p>
            <ui-button raised @click="invite">Invite</ui-button>
          </p>
        </ui-panel>
      </ui-panels>

  
      
    </div>
  </div>
</template>


<script>
import WeekVue from '../components/WeekVue.vue';
import EntriesEdit from '../components/EntriesEdit.vue'

const tabs = [
  {
    text: 'Home',
    icon: 'playlist_add'
  },
  {
    text: 'entries',
    icon: 'edit_note'
  },
  {
    text: 'Settins',
    icon: 'settings'
  }
];

let format_day = (days_to_remove)=>{
    let date = new Date()
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - (offset*60*1000) - days_to_remove * 86400000)
    return date.toISOString().split('T')[0]
}

let start = new Date().getTime() - 4 * 86400000;
const int_format = new Intl.DateTimeFormat('en-us', {weekday:'short', day:'numeric'}).format;
let past_week = [...Array(5).keys()].map((day) =>{
    let date = new Date(start + (day) * 86400000);
    let month = date.getMonth()+1;
    let day_number = date.getDate()
    return {
        day: `${date.getFullYear()}-${ month < 10 ? '0' + month : month}-${ day_number < 10 ? '0' + day_number : day_number}`,
        label: int_format( start + day  * 86400000),
        date: date,
    }
});

let day_options = [
  {
  'label': "Today",
  'value': 0
  },{
  'label': "Yersterday",
  'value': 1
  },{
  'label': past_week[2]["label"],
  'value': 2
  },{
  'label': past_week[1]["label"],
  'value': 3
  },{
  'label': past_week[0]["label"],
  'value': 4
  },
]

export default {
  components: { WeekVue, EntriesEdit },
  name: 'Activity',
  data(){
      return {
          add_count: null,
          day_select: '0',
          day_options:day_options,
          past_week: past_week,
          id:this.$route.params.id,
          submitting: false,
          tabs,
          active_tab:0,
          invite_email: '',
          invite_error_text: '',
      }
  },
  computed:{
    activity(){ return this.$store.state.activity },
    path(){ return this.$route.params.id },
  },
  watch: {
    path(){
      if ( this.$route.params.id ){
        this.$store.dispatch('get_activity', {activity_id:this.$route.params.id})
        this.$store.dispatch('get_entries', {activity_id:this.$route.params.id})
        document.title = this.activity.name || 'Together Challenge';
      }
    }
  },
  methods: {
      async add_entry(){
        this.submitting = true
        this.$store.dispatch("add_entry", {activity_id:this.$route.params.id, entry:{
          member:this.$store.state.users.profile.id,
            day:format_day(this.day_select),
            time:new Date().getTime(),
            last_modified: new Date().getTime(),
            count:parseInt(this.add_count)
        }}).then(()=>{
          this.submitting = false
        })

        this.add_count = null
      },
      async invite(){
        this.invite_error_text = ""
        let users = await this.$store.dispatch('users/search_email', {email:this.invite_email})
        if ( users.length === 0 ){
          this.invite_error_text = "No users found with that email"
        } else if ( users.length === 1 ){
          this.$store.dispatch('add_member', {activity_id:this.$route.params.id,member_id:users[0].user_id})
          this.active_tab = 0
        }

      }
  },
  async created(){
    if ( this.$store.state.activity_id === null ){
      this.$store.commit("set_activity_id", this.$route.params.id )
    }
    this.$store.dispatch("get_activities", {user_id:this.$store.state.users.profile.id, refresh:false} ).then(()=>{
      this.$store.dispatch('get_activity', {activity_id:this.$route.params.id})
      //start loading entries
      this.$store.dispatch('get_entries', {activity_id:this.$route.params.id})
    })
    document.title = this.activity?.name || 'Together Challenge';
  }
}
</script>


<style scoped>
  .input_item {
    height: 56px;
    margin: 0 5px;
    width:min-content;
  }
  .number_input {
    min-width: 100px;
  }
  .day_select {
    max-width: 150px;
    
  }
  #add_entries {
    padding: 2em 0;
  }
  .activity-content {
    padding: 1em;
  }

</style>