<template>
  <div class="">
    <div class="row">
      <div>&nbsp;</div>
      <div v-for="(h, index) in week_headings" :key="index">{{ h.label }}</div>
    </div>
    <div id="members" v-for="member in members" :key="member.id">
      <div class="row">
        <div>{{ friends[member.id] ? friends[member.id].name : member.name }}</div>
        <div v-for="(day, index) in member.days" :key="index">
          {{ day.sum }}
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "week-vue",
  props: {
    id: { required: true, type: String },
    past_week: { required: true, type: Object },
  },
  computed: {
    members() {
      if (!this.$store.state.activity.entries) {
        return [];
      }
      for (const [key, member] of Object.entries(this.activity.members)) {
        member.id = key
        member.days = [];
        this.past_week.forEach((day) => {
          let day_entries = this.$store.state.activity.entries.filter((e) => {
            return e.member === key && e.day === day.day;
          });
          let sum = 0;
          day_entries.forEach((e) => (sum += parseInt(e.count)));
          member.days.push({
            count: day_entries.length,
            sum: sum,
          });
        });
      }
      return this.activity.members;
    },
    activity(){
      return this.$store.state.activity;
    },
  },
  data() {
    return {
      week_headings: this.past_week,
      friends: []
    };
  },
  async mounted(){
    await this.$store.dispatch('users/get_friends', {user_ids:Object.entries(this.activity.members).map(m=>m[0])});
    (this.$store.state.users.friends || []).forEach(f=>{
      this.friends[f.user_id] = f
    });

  }
};
</script>


<style scoped>
.row {
  display: flex;
  flex-direction: row;
}
.row div {
  flex-basis: 20%;
  padding: 1em;
}
</style>