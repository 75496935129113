<template>
  <div v-for="entry in my_entries" :key="entry.id">
    
    <ui-card class="card">
      <!-- <ui-card-content class="card-content">
        
      </ui-card-content> -->
      <ui-card-actions>
        <h4>{{entry.day}}</h4>: {{entry.count}}
        <ui-card-icons>
          <ui-icon-button icon="edit" @click="open_update_modal(entry)"></ui-icon-button>
          <ui-icon-button icon="delete" @click="open_delete_modal(entry)"></ui-icon-button>
        </ui-card-icons>
      </ui-card-actions>
    </ui-card>
  </div>

  <!-- Delete entry -->
  <ui-dialog v-model="show_delete_modal" @confirm="confirm_delete">
    <ui-dialog-title>Delete Entry?</ui-dialog-title>
    <ui-dialog-content>
      <p>
        {{entry_to_delete.day}}: {{entry_to_delete.count}}
      </p>
    </ui-dialog-content>
    <ui-dialog-actions></ui-dialog-actions>
  </ui-dialog>

  <!-- Update entry -->
  <ui-dialog v-model="show_update_modal" @confirm="confirm_update">
    <ui-dialog-title>Update Entry</ui-dialog-title>
    <ui-dialog-content>
      <p>
        {{entry_to_update.day}}
      </p>
      <ui-textfield type="number" input-type="number" v-model="entry_to_update.count"></ui-textfield>
    </ui-dialog-content>
    <ui-dialog-actions></ui-dialog-actions>
  </ui-dialog>


</template>


<script>
export default {
  name: "edit-entries",
  
  computed: {
    activity(){ return this.$store.state.activity },
    my_entries(){
      if (!this.activity.entries){
        return [];
      }
      let my_entries = this.activity.entries.filter(e=>e.member===this.$store.state.users.profile.id)
      return my_entries;
    }
  },
  data() {
    return {
        show_delete_modal: false,
        entry_to_delete: {},
        show_update_modal: false,
        entry_to_update: {}
    }
  },
  methods: {
    open_delete_modal(entry){
      this.entry_to_delete = entry
      this.show_delete_modal = true
    },
    open_update_modal(entry){
      console.log(entry);
      this.entry_to_update = entry
      this.show_update_modal = true
    },
    async confirm_delete(result) {
      if (result) {
        await this.$store.dispatch("delete_entry", {activity_id:this.activity.id, entry_id: this.entry_to_delete.id})
      }
    },
    async confirm_update(result) {
      console.log(result);
      if (result) {
        this.entry_to_update.last_modified = new Date().getTime()
        this.entry_to_update.count = parseInt(this.entry_to_update.count)
        await this.$store.dispatch("update_entry", {activity_id:this.activity.id, entry: this.entry_to_update})
      }
    }
  },
  async created() {
    
  }
};
</script>


<style scoped>
  .card-content {
    text-align: start;
    padding:16px
  }
</style>